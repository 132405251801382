<template>
  <div class="bookmaster-wraper">
    <kyy-app-modal-news-selector ref="modal_new_selector" :selectedNews="entry.news_ids" @handleSelectNews="handleSelectNews"></kyy-app-modal-news-selector>
    <app-modal-confirm
      ref="apikey_modal_save"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="createOrUpdate()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("home_setting.confirm_update_setting") }}</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >

      <form class="form-horizontal">
        <div class="row">
          <div class="col-12">
            <div class="card card-body">
              <template v-if="idEditing">
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
                  <label class="col-xl-1 col-lg-2 col-sm-2 m-t-8">{{ entry.id }}</label>
                </div>
              </template>

              <!-- Banner image -->
              <div class="form-group row ">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("home_setting.banner_path") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-single-file-input accept="image/jpeg, image/png, image/jpg" name="banner"
                    :imageSrc="entry.banner_url"
                    @input="handlerUploadBannerImage"
                    :allowedExtension="allowedExtension"
                    :rules="{
                      width: 2048,
                      height: 2048
                    }"
                  >
                  </app-single-file-input>
                </div>
              </div>

              <!-- Intro title JA-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("home_setting.introduction_title") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="introduction_title"
                    type="string128"
                    v-model="entry.introduction_title"
                  />
                </div>
              </div>

              <!-- Intro title JA -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("home_setting.introduction_title_vn") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="introduction_title_vn"
                    type="string128"
                    v-model="entry.introduction_title_vn"
                  />
                </div>
              </div>

              <!-- Introduction JA -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("home_setting.introduction") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-textarea
                    name="introduction"
                    type="string128"
                    v-model="entry.introduction"
                  />
                </div>
              </div>

              <!-- Introduction VN -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("home_setting.introduction_vn") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-textarea
                    name="introduction_vn"
                    type="string128"
                    v-model="entry.introduction_vn"
                  />
                </div>
              </div>

              <!-- Intro image -->
              <div class="form-group row ">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("home_setting.introduction_path") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-single-file-input accept="image/jpeg, image/png, image/jpg" name="banner"
                    :imageSrc="entry.introduction_url"
                    @input="handlerUploadIntroImage"
                    :allowedExtension="allowedExtension"
                    :rules="{
                      width: 2048,
                      height: 2048
                    }"
                  >
                  </app-single-file-input>
                </div>
              </div>

              <!-- Home page news -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">Tin tức trang chủ</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <button type="button" class="btn btn-primary" @click="handleOpenNewsSelector">Chọn list tin tức trang chủ</button>
                </div>
              </div>

              <!-- Gallery Text JA-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("home_setting.gallery_text") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="gallery_text"
                    type="string128"
                    v-model="entry.gallery_text"
                  />
                </div>
              </div>

              <!-- Gallery Text VM-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("home_setting.gallery_text_vn") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="gallery_text_vn"
                    type="string128"
                    v-model="entry.gallery_text_vn"
                  />
                </div>
              </div>

              <!-- Home page gallery -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("home_setting.gallery") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-single-file-input
                    class="mb-2"
                    v-for="(image, index) in entry.gallery"
                    :key="index"
                    accept="image/jpeg, image/png, image/jpg"
                    :imageSrc="entry.gallery[index].image_url"
                    @input="handlerUploadGalleryImage($event, index)"
                    :rules="{
                      width: 2560,
                      height: 2560
                    }"
                    :allowedExtension="allowedExtension"
                    deleteBtn="true"
                    @handlRemoveImage="handlRemoveGalleryImage(index)"
                  >
                  </app-single-file-input>
                  <button type="button" class="btn btn-primary" @click="addNewImageGallery">Thêm ảnh gallery</button>
                </div>
              </div>

              <div class="card-footer row justify-content-end">
                <button :disabled="!valid && validated" class="btn btn-primary m-r-10" type="button" @click="handleSaveSetting" >
                  {{  $t('common_vn.update') }}
                </button>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import moment from 'moment';
import AppTextarea from '@components/forms/AppTextarea.vue';
import KyyAppModalNewsSelector from "@components/_common/popup/KyyAppModalNewsSelector";
import { isNull } from 'lodash';
export default {
  name: "HomeSetting",

  components: {
    KyyAppModalNewsSelector,
    AppTextarea
  },

  data() {
    return {
      entry: {
        id: null,
        banner_path: null,
        banner_url: null,
        is_new_banner: false,
        introduction_title: '',
        introduction_title_vn: '',
        introduction_path: null,
        introduction_url: null,
        is_new_intro_image: false,
        introduction: '',
        introduction_vn: '',
        gallery_text: '',
        gallery_text_vn: '',
        gallery: [
          {
            image_path: null,
            image_url: null,
            is_new: true,
          }
        ],
        news_ids: [],
      },
      idEditing: null,
      allowedExtension : ['image/jpeg', 'image/png', 'image/jpg']
    }
  },

  methods: {
    async getHomeSetting() {
      let response = await this.$request.get(this.ENDPOINT.KYY_HOME_SETTING);
      if (response.hasErrors()) {
        if (response.status === 'error'){
          this.__noticeError(this.$t(response.data.error_msg));
        }
      } else {
        if (response.data.data) {
          this.entry = _.cloneDeep(response.data.data);
          this.entry.is_new_banner = false;
          this.entry.is_new_intro_image = false;
        }
      }
    },

    handleSaveSetting() {
      this.$refs.apikey_modal_save.show();
    },

    async createOrUpdate() {
      let res = null;
      let msg = "";
      let params = _.cloneDeep(this.entry);
      params.gallery = params.gallery.filter((image) => {
        return !isNull(image.image_path) 
      })
      res = await this.$request.post(this.ENDPOINT.KYY_SAVE_HOME_SETTING, params);
      msg = this.$t("common_vn.save_ok")
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        this.$refs.apikey_modal_save.hide();
        this.__noticeSuccess(msg);
        this.getHomeSetting()
      }
    },

    handlerUploadBannerImage(data) {
      this.entry.banner_path = data
      this.entry.banner_url = data
      this.entry.is_new_banner = true
    },

    handlerUploadIntroImage(data) {
      this.entry.introduction_path = data
      this.entry.introduction_url = data
      this.entry.is_new_intro_image = true
    },

    handleOpenNewsSelector() {
      this.$refs.modal_new_selector.show();
    },

    handlerUploadGalleryImage(data, index) {
      this.entry.gallery[index].image_path = data
      this.entry.gallery[index].is_new = true
    },

    addNewImageGallery() {
      this.entry.gallery.push({
        image_path: null
      })
    },

    handleSelectNews(selectedNews) {
      this.entry.news_ids = selectedNews
    },

    handlRemoveGalleryImage(index) {
      this.entry.gallery.splice(index, 1)
    }
  },

  mounted: function () {
    this.getHomeSetting()
  },
}
</script>
