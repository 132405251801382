<template>
  <app-modal
    ref="modal"
    modal-header-title="注文一覧から選択"
    size="lg"

  >
    <template v-slot:header>
      <div class="modal-header clearfix text-left">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-hidden="true"
          @click="handlerClose()"
        >
          x
        </button>
        <span class="title">Chọn tin tức hiển thị</span>
      </div>
    </template>
    <template v-slot:container>
      <app-basic-table
        ref="basic_table"
        :table-name="$t('news.list_title')"
        :filters.sync="filters"
        :setting-columns="columns"
        :endpoint-list="ENDPOINT.KYY_NEWS_LIST"
        @resetAllSearch="onResetAllSearch"
        @searched="onSearch"
      >
        <template v-slot:filters>
          <div class="row">
            <div class="col-xl-6 col-lg-12 col-sm-12">
              <div class="form-group">
                <label class="col-sm-12">{{ filters.title.label }}</label>
                <div class="col-sm-12 gr-icheck mt-0">
                  <app-input :name="filters.title.name" input-style="normal"
                            v-model="filters.title.value"
                  />
                </div>
              </div>
            </div>
            <!-- Search by category -->
            <div class="col-xl-6 col-lg-12 col-sm-12">
              <div class="form-group">
                <label class="col-sm-12">{{ filters.kyy_news_category_id.label }}</label>
                <div class="col-sm-12 gr-icheck mt-0">
                  <app-select :name="filters.kyy_news_category_id.name"
                    input-style="normal"
                    v-model="filters.kyy_news_category_id.value"
                    :options-data="meta.categories"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:body-cell-category="props">
          <td class="app-align-middle text-center app-p-none">
            {{ getLabelFromOptionArray(props.row.kyy_news_category_id, meta.categories) }}
          </td>
        </template>

        <template v-slot:body-cell-select="props">
          <td class="app-align-middle text-center app-p-none">
            <div class="icheck-primary icheck-nolabel d-inline">
              <input
                :id="'checkbox' + props.row.id"
                :key="'checkbox' + props.row.id"
                type="checkbox"
                :checked="isTempSelected(props.row.id)"
                @click="handleSelectItem(props.row.id)"
              />
              <label :for="'checkbox' + props.row.id"></label>
            </div>
          </td>
        </template>

        <template v-slot:body-cell-image_path="props">
          <td class="app-align-middle" style="display: flex;justify-content: center;">
            <img :src="props.row.image.url" v-if="props.row.image.path" style="width: 100px; height: 100px">
          </td>
        </template>
      </app-basic-table>
      <!-- footer -->
      <div class="d-flex justify-content-end mt-2">
        <button
          @click="handleSubmit"
          type="button"
          :class="['btn btn-primary']"
        >
          {{ $t("common_vn.confirm") }}
        </button>
      </div>
    </template>
  </app-modal>
</template>

<script>
  import AppModal from "../../../components/_common/popup/AppModal";
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "@components/forms/AppDateRangepicker";
  import {ENDPOINT} from "@constants/api";
  import {getLabelFromOptionArray} from "@utils";

  export default {
    name: "KyyAppModalNewsSelector",
    components: {
      AppModal,
      AppDateRangePicker,
      "app-basic-table": AppBasicTable,
    },
    props : {
      selectedNews: {
        type: Array,
        default: () => []
      },
    },
    watch: {
      selectedNews: function (newValue) {
        this.tempSelectedNews = newValue
      }
    },
    methods: {
      hide() {
        this.$refs.modal.hide();
      },

      show() {
        this.$refs.modal.show();
        // Refresh table data
        this.$refs.basic_table.getEntries();
      },

      handlerClose() {
      },

      handlerSelectOrder(data) {
      },

      onResetAllSearch() {
      },

      onSearch() {
      },

      handleSelectItem(newsID) {
        if (this.tempSelectedNews.includes(newsID)) {
          this.tempSelectedNews = this.tempSelectedNews.filter(function(id) {
              return id !== newsID
          })
        } else {
          this.tempSelectedNews.push(newsID)
        }
      },

      isTempSelected(newsID) {
        return this.tempSelectedNews.includes(newsID)
      },

      handleSubmit() {
        this.$emit('handleSelectNews', this.tempSelectedNews);
        this.hide();
      }
    },
    data() {
      return {
        filters: {
          title: {
            name: "title",
            condition: "like",
            label: this.$t("news.title")
          },
          kyy_news_category_id: {
            name: "kyy_news_category_id",
            condition: "equal",
            label: this.$t("news.category")
          },
        },
        columns: [
          {name: "select", label: this.$t("common_vn.action"), textAlign: 'text-center'},
          {name: "image_path", label: this.$t("news.thumbnail"), textAlign: 'text-center'},
          {name: "category", label: this.$t("news.category"), textAlign: 'text-center'},
          {name: "title", label: this.$t("news.title"), sortable: true, textAlign: 'text-left'},
        ],
        meta: [],
        tempSelectedNews: [],
        loading : true
      }
    },
    mounted: async function () {
      this.$request.get(this.ENDPOINT.UI_HELPER_GET_NEWS_CATEGORIES).then(res => {
        let categories = _.cloneDeep(res.data.categories);
        categories = categories.map((category) => {
          return {
            id: category?.id,
            name: category?.name
          }
        })
        categories.unshift({
          id: 'all',
          name: '',
        });
        this.meta = {
          categories: categories,
        }
        this.tempSelectedNews = _.cloneDeep(this.selectedNews)
      })
    },
  }
</script>
<style lang="scss" scoped>
.icheck-nolabel>label {
  padding-left: 0 !important;
}
.icheck-nolabel>label::before {
  margin-left: 0 !important;
}
</style>
